import { AnalyticsBrowser } from '@segment/analytics-next';
import { createContext, useMemo } from 'react';

export const AnalyticsContext = createContext<AnalyticsBrowser>(undefined!);

type Props = {
  writeKey: string;
  children: React.ReactNode;
};
export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const analytics = useMemo(
    () => AnalyticsBrowser.load({ writeKey }),
    [writeKey],
  );

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
