import * as Sentry from '@sentry/react';
import { AnalyticsProvider } from 'contexts/Analytic.context';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// TODO: Take another look at this after v3 release & we have removed v2
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-calendar-timeline/lib/Timeline.css';

const App = React.lazy(() =>
  process.env.REACT_APP_ENABLE_V3 === 'true'
    ? import('./v3/App')
    : import('./App'),
);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_V3,
  integrations: [
    new Sentry.BrowserTracing(),
    ...(process.env.REACT_APP_ENVIRONMENT === 'staging' ||
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? [
          new Sentry.Replay({
            maskAllText: process.env.REACT_APP_ENVIRONMENT !== 'staging',
            maskAllInputs: process.env.REACT_APP_ENVIRONMENT !== 'staging',
          }),
        ]
      : []),
  ],
  environment: process.env.REACT_APP_ENVIRONMENT || 'development',
  tracesSampleRate: 0.1,

  replaysSessionSampleRate: 0.1, // Capture replay for 10% of sessions
  replaysOnErrorSampleRate: 1.0, // Capture replay for 100% of error sessions
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AnalyticsProvider writeKey={process.env.REACT_APP_SEGMENT_KEY || ''}>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </AnalyticsProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
